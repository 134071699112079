<template>
  <el-button type="primary" v-bind="attrs"
  >
    <el-icon>
      <Search/>
    </el-icon
    >
  </el-button>
</template>

<script setup>
import {Search} from "@element-plus/icons-vue";
import {useAttrs} from "vue";

const attrs = useAttrs()
</script>

<style lang="scss" scoped>

</style>