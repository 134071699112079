import request from '@utils/http'

//流水记录
export function stockFlowList(data) {
    return request({
        url: "/carton/stock/flow/record",
        method: 'post',
        data
    })
}


//流水详情
export function stockFlowDetail(data) {
    return request({
        url: "/carton/stock/flow/record/detail",
        method: 'post',
        data
    })
}


//操作记录
export function stockOperateList(data) {
    return request({
        url: "/carton/stock/flow/operate/record",
        method: 'post',
        data
    })
}
