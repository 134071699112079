import {typeOptions} from "@utils/config";
import {ref} from 'vue';
import {typeInfo} from "@/utils/api/type";
import {removeNoChild} from "@utils/tool";
import message from "@utils/tool/message";

export default function () {
    const typeList = ref([]);
    const getTypeList = async () => {
        const {data, code, msg} = await typeInfo({})
        if (code !== 0) {
            message.error(msg);
            return;
        }
        let list = data?.list || []
        //排除掉list 里面不包含childList的数据
        list = removeNoChild(list);
        typeList.value = list;
    };
    return {
        typeList,
        getTypeList,
        typeOptions
    }
}