<template>
  <div :class="[
              'zh-label-text',
              `${
                row.orderType < 6 ? 'in-stock' : 'out-stock'
              }`,
              `order-type-${row.orderType}`,
            ]">{{ row.orderTypeDesc }}
  </div>
</template>

<script setup>
import {toRefs} from "vue";

const props = defineProps({
  row: {
    type: Object,
    default: () => {
    }
  }
})
const {row} = toRefs(props)
</script>

<style lang="scss" scoped>

</style>