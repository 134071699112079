<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-17 10:46:57
 * @FilePath: /aurora-carton-web/src/views/inStock/inventoryRecord/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="proOrder animate__fadeIn" >
      <div class="content" v-loading="loading">
      <el-tabs v-model="tabChecked" class="demo-tabs" @tab-click="tabCheck">
        <el-tab-pane :label="tabTextIn" name="incomplete">
          <Incomplete v-if="tabChecked === 'incomplete'"/>
        </el-tab-pane>
        <el-tab-pane :label="tabText" name="operate">
          <Operate v-if="tabChecked === 'operate'"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import Incomplete from './components/incomplete';
import Operate from './components/operat'
import {useLoading} from "@utils/tool/loading";

export default {
  name:"orderList",
  components:{
    Incomplete,
    Operate
  },
  setup () {
    const state = reactive({
      tabChecked: "incomplete",
      tabText: '操作记录',
      tabTextIn:'业务流水',
    })
    const tabCheck = function(tab,e){
    }
    const {loading} = useLoading();
    return {
      ...toRefs(state),
      tabCheck,
      loading
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
.proOrder{
  height: 100%;
  padding:16px;
  box-sizing: border-box;
  position: relative;
  .el-tabs__header{
    margin-bottom: 0;
  }
  >.content{
    border-radius: 16px;
    background: #fff;
    padding: 12px 32px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }
}

:deep(.el-tabs__content) {
  height:96%;
}

:deep(.el-tabs) {
  height: 100%;
}
</style>
