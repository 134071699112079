/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-18 14:50:15
 * @FilePath: /aurora-carton-web/src/views/inStock/InventoryRecord/orderConfig.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {orderOperateType} from '@/utils/tool/orderTools'
export const tabTitle ={
    //流水记录表头
  inColumns:[
    {
      title: "仓库名称",
      fieldKey: "stockName",
      isShow: true,
      width: 150,
      className: 'table_text'
    },
    {
      title:"产品名称",
        fieldKey: "productName",
      isShow:true,
        className: 'table_text2'
    },
    {
      title:"简称",
        fieldKey: "subName",
      isShow:true
    },
    {
        title: "产品单位",
        fieldKey: "scatteredName",
      width: 200,
      isShow:true,
        className: 'table_text2'
    },
    {
      title:"规格/型号",
        fieldKey: "modelName",
      width: 200,
      isShow:true,
    },
    {
      title: "产品品牌",
      fieldKey: "brandName",
      width: 200,
      className: 'table_text2',
      isShow: true,
    },
    {
      title: "产品分类",
      fieldKey: "classifyName",
      width: 120,
      className: 'table_text2',
      isShow: true,
    },
    {
      title:"类型",
        fieldKey: "orderTypeDesc",
      width:200,
      isShow:true,
    },
    {
      title:"客户/供应商名称",
        fieldKey: "thirdName",
      width:200,
      isShow:true,
    },
    {
      title:"数量",
        fieldKey: "operateCount",
      width:200,
      isShow:true,
    },
    {
      title:"操作人",
        fieldKey: "operateUserName",
      width:200,
      isShow:true,
    },
    {
      title:"操作时间",
        fieldKey: "operateTime",
      width:200,
      isShow:true,
    },
  ],
}

export const operateColumns = [
  {
    title: "仓库名称",
    fieldKey: "stockName",
    isShow: true,
    width: 150,
    className: 'table_text'
  },
  {
    title: "产品名称",
    fieldKey: "productName",
    isShow: true,
    className: 'table_text2'
  },

  {
    title: "简称",
    fieldKey: "subName",
    isShow: true
  },
  {
    title: "标签序列号",
    fieldKey: "serial",
    isShow: true,
    width: 200,
    className: 'table_text2'
  },
  {
    title: "规格/型号",
    fieldKey: "modelName",
    width: 200,
    isShow: true,
  },
  {
    title: "产品品牌",
    fieldKey: "brandName",
    width: 200,
    className: 'table_text2',
    isShow: true,
  },
  {
    title: "产品单位",
    fieldKey: "scatteredName",
    width: 200,
    isShow: true,
    className: 'table_text2'
  },
  {
    title: "类型",
    fieldKey: "orderTypeDesc",
    width: 200,
    isShow: true,
  },
  {
    title: "数量",
    fieldKey: "scatteredCount",
    width: 100,
    isShow: true,
  },
  {
    title: "操作人",
    fieldKey: "operateUserName",
    width: 200,
    isShow: true,
  },
  {
    title: "操作时间",
    fieldKey: "operateTime",
    width: 200,
    isShow: true,
  },
]

export const resetOrderOperateType = {
  1: 999,
  2: 999,
  3: orderOperateType.SCATTERED_IN,
  4: orderOperateType.SCATTERED_BOX,
  5: orderOperateType.SHELF,
  6: orderOperateType.UN_SHELF,
}
