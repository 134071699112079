<template>
  <div class="warehouse">
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select bdr"
                   v-model="formData.stockId" @change="getList">
          <el-option v-for="item in formStockList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
        </el-select>
      </div>
      <div class="queryItem">
        <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled-date="isOverToday"
            :clearable="false"
            @change="getList"
        />
        <el-input
            v-model="formData.query"
            class="w200"
            placeholder="产品名称/简称/条码"
            @keydown.enter="getList"
            clearable
            v-elInput
        />
        <type-cascader v-model="formData.orderType" @change="getList"  class="w200"></type-cascader>
        <scattered-select @change="getList" class="w200" v-model="formData.scattered"></scattered-select>
        <el-cascader
            v-model="formData.classifyList"
            :options="typeList"
            :props="typeOptions"
            placeholder="产品分类"
            class="w200"
            @change="changeClassify"
            filterable
            clearable
        />
        <SelectByAbcModal placeholder="选择品牌" typeName="brand" :maxNum="1" v-model:list="formData.searchList"
                          className="w200" @change="changeBrand"></SelectByAbcModal>
        <SelectByAbcModal
            typeName="supplier"
            :maxNum="1"
            v-model:list="formData.supplier"
            className="w200"
            @change="getList"
            placeholder='供应商名称'
        ></SelectByAbcModal>
        <SelectByAbcModal
            typeName="punter"
            :maxNum="1"
            v-model:list="formData.punter"
            className="w200"
            @change="getList"
            placeholder='客户名称'
        ></SelectByAbcModal>
        <div class="queryBtn">
          <search-btn @click="getList" class="square"></search-btn>
          <export-btn :form-data="formData" :list-length="tableData.length" type="flow"></export-btn>
        </div>
      </div>
    </div>
    <query-form census-height="0px">
      <zh-table
          :data="tableData"
      >
      <!-- 列表 -->
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
        <template v-for="item in titleListIn" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'orderTypeDesc'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
              show-overflow-tooltip
          >
            <template #default="{row}">
              <status-btn :row="row"></status-btn>
            </template>
          </el-table-column>
          <el-table-column
              v-else-if="item.fieldKey === 'operateCount'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
          >
            <template #default="{row}">
              <span class="table_text">{{ row.operateCount }}</span><span class="contBtn ml_8" @click="showRfid(row)"
                                                                          v-if="row.haveLabel">查看明细</span>
            </template>
          </el-table-column>
          <el-table-column
              v-else-if="item.fieldKey === 'scatteredName'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              show-overflow-tooltip
              :class-name="item.className || 'table_text'"
          >
            <template #default="scope">
              <div
                  v-html="getUnitName(scope.row)">
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-else
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
              show-overflow-tooltip
          >
          <template #default="{row}">
              <div v-html="row[item.fieldKey] || '--'"></div>
            </template>
          </el-table-column>
        </template>
      </zh-table>
    </query-form>
    <div class="mainPage">
      <el-pagination
          v-model:current-page="pageInfo.pageNumber"
          layout="total, prev, pager, next , jumper"
          :total="pageInfo.total"
          v-model:page-size="pageInfo.pageSize"
          @current-change="handleCurrentChange"
      />
    </div>
    <RfidList v-model="rfidShow" v-model:goodsInfo="goodsInfo"></RfidList>
  </div>
</template>
<script setup>
import StatusBtn from "@components/element/statusBtn.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import {tabTitle} from '../orderConfig.js'
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import {stockList} from '@/utils/api/procurement/stock.js'
import {usePagination} from "@utils/tool/page";
import {typeInfo} from "@utils/api/type";
import {getGoodsConfig, typeOptions} from "@utils/config";
import scatteredSelect from "@components/product/scatteredSelect/index.vue";
import queryForm from "@/components/element/queryForm.vue";
import moment from "moment";
import ZhTable from "@components/element/table.vue";
import RfidList from "./component/RfidDetail.vue";
import {productInfo} from '@utils/api/product/product.js';
import {stockFlowList} from "@utils/api/inbound/flow.js";
import {isOverToday, reactiveAssign, removeNoChild} from "@utils/tool";
import ExportBtn from "@components/exportBtn/index.vue";
import SearchBtn from "@components/element/searchBtn.vue";
import {getUnitName} from "@utils/tool/orderTools";
import TypeCascader from "@components/form/TypeCascader/index.vue";

const {goodsInfo, retGoodsInfo} = getGoodsConfig()
const {proxy} = getCurrentInstance();
const loading = ref(false);
const formStockList = ref([])
//控组详情弹窗是否展示
const orderShow = ref(false);
const titleListIn = tabTitle.inColumns
const tableData = ref([]);
const typeList = ref([]);
const rfidShow = ref(false);
//获取仓库列表
const getStockListData = async () => {
  let res = await stockList({
    all: false,
    status: true,
  })
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formData.stockId = res.data.list[0].stockId
      formStockList.value = res.data.list
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      formStockList.value.push({stockId: "", stockName: '全部仓库'})
      formStockList.value.push(...res.data.list)
    }
  } else {
    proxy.$message.error(res.msg)
  }
}



const formData = reactive({
  query: "",
  stockId: '',
  time: [],
  searchList: [],
  orderType: [],
  classifyId: "",
  classifyList: [],
  supplier: [],
  punter: [],
  brandId: '',
  scattered:''
});
const {pageInfo} = usePagination()
//获取配置文件


const getTypeList = async () => {
  typeInfo({}).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data?.list || []
    //排除掉list 里面不包含childList的数据
    list = removeNoChild(list);
    typeList.value = list;
  });
};

const changeClassify = () => {
  if (formData.classifyList?.length) {
    formData.classifyId = formData.classifyList[formData.classifyList?.length - 1] || ''
  } else {
    formData.classifyId = ''
  }
  getList()
}

const changeBrand = () => {
  if (formData.searchList?.length) {
    formData.brandId = formData.searchList[0]?.brandId || ''
  } else {
    formData.brandId = ''
  }
  getList()
}

const getList = () => {
  if (formData.supplier?.length && formData.punter?.length) {
    tableData.value = []
    return
  }
  let beginTime = 0
  let endTime = 0
  if (formData.time && formData.time[0]) {
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if (formData.time && formData.time[1]) {
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  const orderType = formData.orderType?.[0] ?? ''
  let typeId = formData.orderType?.[1] ?? ''
  let obj = {
    ...formData,
    typeId,
    orderType:orderType,
    beginTime: moment(beginTime).valueOf(),
    endTime: moment(endTime).valueOf(),
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    brandId: formData.searchList[0]?.brandId || '',
    thirdId: formData.supplier[0]?.supplierId || formData.punter[0]?.punterId || '',
  };
  stockFlowList(obj)
      .then((res) => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg);
          return;
        }
        tableData.value = res.data?.list || [];
        tableData.value.forEach((item) => {
          item.operateTime = moment(item.operateTime).format('YYYY-MM-DD HH:mm:ss')
          item.operatePrice = proxy.$filters.toYuan(item.operatePrice,'元')
          if (!item.thirdName) {
            item.thirdName = '--'
          }
        });
        pageInfo.total = res?.data?.total || 0;
      })
};

//打开rfid列表
const showRfid = async (e) => {
  retGoodsInfo()
  reactiveAssign(goodsInfo, e)
  await getGoodsDetail(e)
}
const getGoodsDetail = (e) => {
  productInfo({
    productId: e.productId,
    scattered:e.scattered
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(goodsInfo, res.data)
    rfidShow.value = true;
  })
}
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};

//设置时间
function setTime() {
  formData.time = [moment().format('YYYY-MM-DD') + ' 00:00:00', moment().format('YYYY-MM-DD') + ' 23:59:59']
}

onMounted(async () => {
  setTime()
  await Promise.all([getStockListData(), getTypeList()])
  getList()
});
const closeDetail = () => {
  orderShow.value = false;
  getList();
};
</script>

<script>
export default {
  name: "warehouse",
  components: {
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.warehouse {
  padding-top: 8px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 14px;
      }

      .add_text {
        font-size: 14px;
        margin-left: 4px;
        height: 12px;
        line-height: 1;
        // position: relative;
        // top: 0.5px;
      }
    }
  }

  .imgList {
    img {
      width: 56px;
      height: 42px;
      border-radius: 8px;
      overflow: hidden;
    }

    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;

      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }

      .more {
        color: #73737f;
      }
    }

    .statusBtn {
      display: flex;
      justify-content: center;
    }

    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;

      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }

  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
