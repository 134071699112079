<template>
  <div class="flow-record-operate">
    <form @submit.prevent="getList" class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select bdr"
                   v-model="formData.stockId" @change="getList">
          <el-option v-for="item in stockList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
        </el-select>
      </div>
      <div class="queryItem">
        <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled-date="isOverToday"
            :clearable="false"
            @change="getList"
        />
        <el-input
            v-model="formData.query"
            class="w200"
            placeholder="产品名称/简称/条码"
            @keydown.enter="getList"
            clearable
            v-elInput
        />
        <search-serial class="w200" v-model="formData.serial" @on-change="getList"></search-serial>
        <scattered-select @change="getList" class="w200" v-model="formData.scattered"></scattered-select>
        <el-cascader
            v-model="formData.classifyList"
            :options="typeList"
            :props="typeOptions"
            placeholder="产品分类"
            class="w200"
            @change="changeClassify"
            filterable
            clearable
        />
        <select-by-modal-new type="brand" @on-change="getList" v-model="formData.brandId"
                             class="w240 mr_16"></select-by-modal-new>
        <div class="queryBtn">
          <search-btn @click="getList" class="square"></search-btn>
        </div>
      </div>
    </form>
    <query-form census-height="0px">
      <zh-table
          :data="tableData"
      >
        <!-- 列表 -->
        <el-table-column
            type="index"
            width="100"
            label="序号"
            align="center"
            fixed
        />
        <template v-for="item in titleListIn" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'orderTypeDesc'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
              show-overflow-tooltip
          >
            <template #default="{row}">
              <status-btn :row="row"></status-btn>
            </template>
          </el-table-column>
          <el-table-column
              v-else-if="item.fieldKey === 'scatteredName'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
          >
            <template #default="scope">
              <div
                  v-html="getUnitName(scope.row)">
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-else-if="item.fieldKey === 'serial'"
              align="center"
              :label="item.title"
              :width="item.width"
              :class-name="item.className || 'table_text'"
          >
            <template #default="scope">
              <div @click="handleClickSerial(scope.row)">{{
                  scope.row[item.fieldKey] || '--'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-else
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
              show-overflow-tooltip
          >
            <template #default="{row}">
              <div v-html="row[item.fieldKey] || '--'"></div>
            </template>
          </el-table-column>
        </template>
      </zh-table>
    </query-form>
    <div class="mainPage">
      <el-pagination
          v-model:current-page="pageInfo.pageNumber"
          layout="total, prev, pager, next , jumper"
          :total="pageInfo.total"
          v-model:page-size="pageInfo.pageSize"
          @current-change="handleCurrentChange"
      />
    </div>
    <rfid-history v-model="rfidShow" v-model:rfidInfo="rfidInfo" @handleClose="closeRfid"></rfid-history>
  </div>
</template>
<script setup>
import StatusBtn from "@components/element/statusBtn.vue";
import {operateColumns, resetOrderOperateType} from '../orderConfig.js'
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import {usePagination} from "@utils/tool/page";
import queryForm from "@/components/element/queryForm.vue";
import moment from "moment";
import ZhTable from "@components/element/table.vue";
import SearchSerial from "@components/form/SearchSerial/index.vue";
import SelectByModalNew from "@components/form/selectByModalNew/index.vue";
import {stockOperateList} from "@utils/api/inbound/flow.js";
import {isOverToday} from "@utils/tool";
import SearchBtn from "@components/element/searchBtn.vue";
import {getUnitName} from "@utils/tool/orderTools";
import scatteredSelect from "@components/product/scatteredSelect/index.vue";
import useProductType from "@utils/hooks/product/useProductType";
import RfidHistory from '@/components/rfidList/rfidHistory.vue';
import useStock from "@utils/hooks/useStock";

const {stockList, getStockListData} = useStock()
const {typeList, getTypeList, typeOptions} = useProductType()

const rfidInfo = reactive({
  serial: '',
})
const {proxy} = getCurrentInstance();
const {pageInfo} = usePagination()


const loading = ref(false);
const titleListIn = operateColumns
const tableData = ref([]);
const rfidShow = ref(false);
//获取仓库列表

const formData = reactive({
  query: "",
  stockId: '',
  time: [],
  orderType: '',
  classifyId: "",
  classifyList: [],
  brandId: '',
  serial: "",
  scattered: '',
});
const changeClassify = () => {
  if (formData.classifyList?.length) {
    formData.classifyId = formData.classifyList[formData.classifyList?.length - 1] || ''
  } else {
    formData.classifyId = ''
  }
  getList()
}

const getList = () => {
  if (formData.supplier?.length && formData.punter?.length) {
    tableData.value = []
    return
  }
  let beginTime = 0
  let endTime = 0
  if (formData.time && formData.time[0]) {
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if (formData.time && formData.time[1]) {
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  let obj = {
    ...formData,
    beginTime: moment(beginTime).valueOf(),
    endTime: moment(endTime).valueOf(),
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
  };
  stockOperateList(obj)
      .then((res) => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg);
          return;
        }
        tableData.value = res.data?.list || [];
        tableData.value.forEach((item) => {
          item.operateTime = moment(item.operateTime).format('YYYY-MM-DD HH:mm:ss')
          item.operatePrice = proxy.$filters.toYuan(item.operatePrice, '元')
          if (!item.thirdName) {
            item.thirdName = '--'
          }
          item.orderType = resetOrderOperateType[item.way];
          item.orderTypeDesc = item.typeDesc || '--'
        });
        pageInfo.total = res?.data?.total || 0;
      })
};

//设置时间
function setTime() {
  formData.time = [moment().format('YYYY-MM-DD') + ' 00:00:00', moment().format('YYYY-MM-DD') + ' 23:59:59']
}

onMounted(async () => {
  setTime()
  await Promise.all([getStockListData(), getTypeList()])
  getList()
});

const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};

const handleClickSerial = (row) => {
  if (!row.serial) {
    return
  }
  Object.assign(rfidInfo, row)
  rfidShow.value = true
}
const closeRfid = () => {

}
</script>

<script>
export default {
  name: "FlowRecordOperate",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.flow-record-operate {
  padding-top: 8px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;

  .imgList {
    img {
      width: 56px;
      height: 42px;
      border-radius: 8px;
      overflow: hidden;
    }

    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;

      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }

      .more {
        color: #73737f;
      }
    }

    .statusBtn {
      display: flex;
      justify-content: center;
    }

    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;

      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }

  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
